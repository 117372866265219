export function convertToUrlFormat(pageTitle: string) {
    return pageTitle.toLocaleLowerCase().replaceAll(' ', '-');
}

export function replaceAllDotsWithTabs(value: string) {
    return value.replaceAll('.', '\t');
}

export function replaceAllTabsWithDots(value: string) {
    return value.replaceAll('\t', '.');
}

export function getDividerString(dividerName: string, wrapperString: string = '___') {
    return `${wrapperString}${dividerName}${wrapperString}`;
}

export function quote(text: string): string {
    return `"${text}"`;
}

export function joinWithHtmlBr(errors: Array<string>): string {
    return errors.join('<br>');
}

export function getSnippedText(text: string, maxLength: number): string {
    if (!text || maxLength <= 0) return '';

    let charCount = 0;
    let endIndex = 0;

    for (; endIndex < text.length; endIndex++) {
        if (text[endIndex] !== ' ') charCount++;
        if (charCount >= maxLength) break;
    }

    while (endIndex < text.length && text[endIndex] !== ' ') {
        endIndex++;
    }

    return endIndex < text.length ? text.slice(0, endIndex) + ' ...' : text;
}
