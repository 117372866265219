import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import {
    AgGridEachTypeExtension,
    AgGridPreferencesActions,
    BasicColumnsState,
    GenericSelector,
    PreferencesEachElement,
    PreferenceType,
} from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ColDef, GridApi } from 'ag-grid-community';
import { cloneDeep, difference } from 'lodash';
import { ColumnDef } from '../../AgGrid/types';

export function saveUserSortModel({
    api,
    setUserPreferences,
    selector,
    columnsSort,
}: {
    api?: GridApi;
    setUserPreferences?: ReturnType<typeof useUserPreferences>['setUserPreferences'];
    selector?: GenericSelector;
    columnsSort?: AgGridEachTypeExtension['columnsSort'];
}) {
    if (!selector || !setUserPreferences || (!api && !columnsSort)) {
        return;
    }

    let _columnsSort: AgGridEachTypeExtension['columnsSort'] = [];

    if (columnsSort) {
        _columnsSort = columnsSort;
    }

    if (api) {
        _columnsSort = api.getColumnState().map((columnState) => ({
            colDef: {
                colId: columnState.colId,
                sort: columnState.sort,
            },
        }));
    }

    setUserPreferences<keyof typeof AgGridPreferencesActions, AgGridEachTypeExtension>(
        PreferenceType.AgGridPreferences,
        {
            ...selector,
            action: AgGridPreferencesActions.SetSortModel,
            columnsSort: _columnsSort,
        },
    );
}

export function saveUserColumnsOrder({
    api,
    setUserPreferences,
    selector,
}: {
    api: GridApi;
    setUserPreferences: ReturnType<typeof useUserPreferences>['setUserPreferences'];
    selector: GenericSelector;
}) {
    if (!selector || !setUserPreferences || !api) {
        return;
    }

    const columnsOrder = api.getColumnState().map((columnState) => ({
        colDef: {
            colId: columnState.colId,
        },
    }));

    setUserPreferences<keyof typeof AgGridPreferencesActions, AgGridEachTypeExtension>(
        PreferenceType.AgGridPreferences,
        {
            ...selector,
            action: AgGridPreferencesActions.UpdateColumnsOrder,
            columnsOrder,
        },
    );
}

export function saveUserColumnsVisibility({
    api,
    setUserPreferences,
    selector,
}: {
    api: GridApi;
    setUserPreferences: ReturnType<typeof useUserPreferences>['setUserPreferences'];
    selector: GenericSelector;
}) {
    if (!selector || !setUserPreferences || !api) {
        return;
    }

    const columnsVisibility = api.getColumnState().map((columnState) => ({
        colDef: {
            colId: columnState.colId,
            hide: columnState.hide || undefined,
        },
    }));

    setUserPreferences<keyof typeof AgGridPreferencesActions, AgGridEachTypeExtension>(
        PreferenceType.AgGridPreferences,
        {
            ...selector,
            action: AgGridPreferencesActions.UpdateColumnsVisibility,
            columnsVisibility,
        },
    );
}

export function saveUserColumnsWidth({
    api,
    setUserPreferences,
    selector,
}: {
    api: GridApi;
    setUserPreferences: ReturnType<typeof useUserPreferences>['setUserPreferences'];
    selector: GenericSelector;
}) {
    if (!selector || !setUserPreferences || !api) {
        return;
    }

    const columnsWidths = api.getColumnState().map((columnState) => ({
        colDef: {
            colId: columnState.colId,
            width: columnState.width || undefined,
        },
    }));

    setUserPreferences<keyof typeof AgGridPreferencesActions, AgGridEachTypeExtension>(
        PreferenceType.AgGridPreferences,
        {
            ...selector,
            action: AgGridPreferencesActions.UpdateColumnsWidth,
            columnsWidths,
        },
    );
}

export function getColumnId(colDef: Partial<ColDef>): string {
    return colDef.colId || colDef.field || '';
}

export function getUserColumnDefs({
    initialColumnDefs,
    userPreferences,
    userPreferencesToApply = {
        columnSorting: true,
        columnsWidths: true,
        columnsOrder: true,
        columnsVisibility: true,
    },
    alwaysFirstColumnClasses = ['no-left-padding', 'horizontal-padding-14'],
}: {
    initialColumnDefs: ColumnDef[];
    userPreferences: PreferencesEachElement<string, AgGridEachTypeExtension>;
    userPreferencesToApply?: {
        columnsWidths: boolean;
        columnSorting: boolean;

        columnsOrder: boolean;
        columnsVisibility: boolean;
    };
    alwaysFirstColumnClasses?: Array<string>;
}): ColumnDef[] {
    let resultColumnDefs = cloneDeep(initialColumnDefs);

    resultColumnDefs = resultColumnDefs.map((initialColumnDef) => {
        // set user column visibility
        if (userPreferencesToApply.columnsVisibility) {
            const userColumnVisibilityDef = userPreferences.columnsVisibility?.find(
                (userColumnDef) => userColumnDef.colDef.colId === getColumnId(initialColumnDef),
            );

            if (userColumnVisibilityDef) {
                initialColumnDef.hide = userColumnVisibilityDef.colDef.hide;
            }
        }

        // set user column width
        if (userPreferencesToApply.columnsWidths) {
            const userColumnWidthDef = userPreferences.columnsWidths?.find(
                (userColumnDef) => userColumnDef.colDef.colId === getColumnId(initialColumnDef),
            );

            if (userColumnWidthDef) {
                initialColumnDef.width = userColumnWidthDef.colDef.width;
            }
        }

        return initialColumnDef;
    });

    // set user sorting
    if (userPreferencesToApply.columnSorting && userPreferences.columnsSort) {
        const userSortedColumn = userPreferences.columnsSort.find((userColumnDef) =>
            ['asc', 'desc'].includes(String(userColumnDef.colDef.sort)),
        );

        resultColumnDefs = resultColumnDefs.map((columnDef) => {
            if (userSortedColumn && userSortedColumn.colDef.colId === getColumnId(columnDef)) {
                columnDef.sort = userSortedColumn.colDef.sort;
                return columnDef;
            }

            // unapply previous sorting
            return {
                ...columnDef,
                sort: null,
            };
        });
    }

    // set user columns order
    if (userPreferencesToApply.columnsOrder && userPreferences.columnsOrder) {
        const userOrderDefs = userPreferences.columnsOrder.map((columnDef) => columnDef.colDef);
        resultColumnDefs.sort((aColumnDef, bColumnDef) => {
            let aIndex = userOrderDefs.findIndex((columnDef) => getColumnId(columnDef) === getColumnId(aColumnDef));

            // if column is not in the user preferences, then locate it in the initial column order
            const getInitialIndex = (columnDef: ColumnDef) =>
                initialColumnDefs.findIndex(
                    (initialColumnDef) => getColumnId(initialColumnDef) === getColumnId(columnDef),
                );

            if (aIndex === -1) {
                aIndex = getInitialIndex(aColumnDef);
            }

            const bIndex = userOrderDefs.findIndex((columnDef) => getColumnId(columnDef) === getColumnId(bColumnDef));

            if (bIndex === -1) {
                return getInitialIndex(bColumnDef);
            }

            return aIndex - bIndex;
        });

        // check if any initial column def contains alwaysFirstColumnClasses (should contain all classes from alwaysFirstColumnClasses)
        const initialColumnDefContainsAlwaysFirstColumnClasses = initialColumnDefs.some((columnDef) =>
            alwaysFirstColumnClasses.every((alwaysFirstColumnClass) =>
                getHeaderClassFrom(columnDef).includes(alwaysFirstColumnClass),
            ),
        );

        // and if so, then remove alwaysFirstColumnClasses from all the columns where it is in initial column defs
        if (initialColumnDefContainsAlwaysFirstColumnClasses) {
            resultColumnDefs = resultColumnDefs.map((resultColumnDef) => {
                const resultColumnDefClasses = getHeaderClassFrom(resultColumnDef);
                const result = {
                    ...resultColumnDef,
                    headerClass: resultColumnDefClasses.filter(
                        (headerClass) => !alwaysFirstColumnClasses.includes(headerClass),
                    ) as string[] | undefined,
                };

                if (result.headerClass?.length === 0) {
                    result.headerClass = undefined;
                }

                return result;
            });
        }

        const indexOfFirstVisibleColumn = resultColumnDefs.findIndex((columnDef) => !columnDef.hide);

        if (indexOfFirstVisibleColumn !== -1) {
            const firstVisibleColumnHeaderClasses = getHeaderClassFrom(resultColumnDefs[indexOfFirstVisibleColumn]);

            // set firstVisibleColumnHeaderClasses for the first visible column
            if (resultColumnDefs[indexOfFirstVisibleColumn]) {
                resultColumnDefs[indexOfFirstVisibleColumn].headerClass = [
                    ...firstVisibleColumnHeaderClasses,
                    ...alwaysFirstColumnClasses,
                ];
            }
        }
    }

    return resultColumnDefs;
}

function getHeaderClassFrom(columnDef?: ColumnDef): Array<string> {
    if (!columnDef || !columnDef?.headerClass) {
        return [];
    }

    if (Array.isArray(columnDef.headerClass)) {
        return columnDef.headerClass;
    }

    return [columnDef.headerClass];
}

// by default this function will keep columns on the same place if they are not in the new columnsOrder
export function getUpdatedNewColumnsState({
    oldColumnsState,
    newColumnsState,
}: {
    oldColumnsState: Array<BasicColumnsState>;
    newColumnsState: Array<BasicColumnsState>;
}): Array<BasicColumnsState> {
    const resultColumnsState = cloneDeep(newColumnsState);

    const newColumnsIds = newColumnsState.map((newColumnState) => getColumnId(newColumnState.colDef));
    const oldColumnsIds = oldColumnsState.map((oldColumnState) => getColumnId(oldColumnState.colDef));

    const oldDiffIds = difference(oldColumnsIds, newColumnsIds);

    oldDiffIds.forEach((oldId) => {
        const oldElementIndex = oldColumnsState.findIndex(
            (oldColumnState) => getColumnId(oldColumnState.colDef) === oldId,
        );

        if (oldElementIndex < newColumnsIds.length) {
            // insert diff element to the same place as in the new columnsOrder
            resultColumnsState.splice(oldElementIndex, 0, oldColumnsState[oldElementIndex]);
            return;
        }

        // if diff element is not in the new columnsOrder, then push it to the end
        resultColumnsState.push(oldColumnsState[oldElementIndex]);
    });

    return resultColumnsState;
}
