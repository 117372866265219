export const getSumOfAllValues = (array: Array<number | null>) => {
    let result = 0;
    array.forEach((value) => {
        if (value !== null) result += value;
    });
    return result;
};

export function sortByDateString(key: string, direction: 'asc' | 'desc') {
    const validDirections = ['asc', 'desc'];

    if (!validDirections.includes(direction)) {
        throw new Error(`Invalid direction: ${direction}. Use 'asc' or 'desc'.`);
    }

    return (a: any, b: any) => {
        const dateA = new Date(a[key]).getTime();
        const dateB = new Date(b[key]).getTime();

        return direction === 'asc' ? dateA - dateB : dateB - dateA;
    };
}

export function areEqualArrays<T>(array1: Array<T>, array2: Array<T>): boolean {
    if (array1.length !== array2.length) {
        return false;
    }

    return array1.every((element, index) => element === array2[index]);
}

export function chunkArray(arr: any[], size: number): any[][] {
    return arr.reduce((chunks, item, index) => {
        if (index % size === 0) {
            chunks.push([item]);
        } else {
            chunks[chunks.length - 1].push(item);
        }
        return chunks;
    }, []);
}

export function filterNullsAndDuplicates<T>(array: T[]): T[] {
    return Array.from(new Set(array.filter((item) => item !== null)));
}

export function hasCommonElements(array1: Array<string>, array2: Array<string>): boolean {
    return array1.some((element) => array2.includes(element));
}
